import api, {
    EligibleRateRequest, KeyValue, Scenario, documentationLevels, TargetPrice, Lookups
} from '@api';
import { MenuItem, Tooltip, Typography } from '@mui/material';
import { fetchProducts, fetchScenarios } from '@redux/entities';
import { useDispatch } from '@redux/store';
import {
    FilledSection, Switch, TextField, CurrencyField, usePageMessage
} from '@tsp-ui/components';
import { isApiError, useAsyncEffect } from '@tsp-ui/utils';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useCallback, useEffect, useRef, useState
} from 'react';
import { DeepPartial, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Form from '../../../common/components/Form';

import styles from './ScenarioForm.module.scss';
import ScenarioPreviewSection, { ScenarioPreviewProps } from './ScenarioPreviewSection/ScenarioPreviewSection';


export interface ScenarioFormProps {
    lookups: Lookups | undefined;
    scenario: Scenario | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setIsFormDirty: Dispatch<SetStateAction<boolean>>;
}

interface ScenarioFormValues extends Omit<Scenario, 'request' | 'targetPrice'> {
    request: EligibleRateRequestFormValues;
    targetPrice: Omit<TargetPrice, 'maxParRate'> & {
        maxParRate: number | '';
    };
}

interface EligibleRateRequestFormValues extends Omit<EligibleRateRequest, 'lockDays' | 'deliveryType'> {
    lockDays: number;
    deliveryType: number | '';
}

const CASH_OUT_LOAN_PURPOSE_VALUE = 3;
const SECOND_MORTAGE_LEIN_POSITION = 2;

export default function ScenarioForm({
    lookups, scenario, setLoading, setIsFormDirty
}: ScenarioFormProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const formMethods = useForm<ScenarioFormValues>({
        defaultValues: convertScenarioToFormValues(scenario)
    });

    const saveScenario = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            const scenario = convertFormValuesToScenario(formValues);

            if (scenario?.id) {
                await api.ppm.scenario.updateScenario(scenario);
            } else {
                await api.ppm.scenario.createScenario(scenario);
            }

            await dispatch(fetchScenarios());

            navigate('/apps/ppm/scenarios');
        } catch (error) {
            handlePossibleEllieError('An error occurred while saving the scenario', error, pageMessage);
        }

        setLoading(false);
        await dispatch(fetchProducts());
    });

    const { handleSubmit, setValue, watch } = formMethods;
    const createFetchResults: ScenarioPreviewProps['createFetchResults'] = useCallback(
        (setResults) => (
            handleSubmit(async (formValues) => {
                try {
                    setResults(await api.ppm.scenario.testScenario(convertFormValuesToScenario(formValues)));
                } catch (error) {
                    handlePossibleEllieError('An error occurred while testing the scenario', error, pageMessage);
                }
            })),
        [ handleSubmit, pageMessage ]
    );

    const state = formMethods.watch('request.property.state');
    const county = formMethods.watch('request.property.county');
    const stateId = lookups?.states.find(stateObj => stateObj.abbreviation === state)?.stateId;

    const hasCountyOptionsRef = useRef(false); // Using a ref to avoid triggering the effect below twice
    const [ countyOptions, setCountyOptions ] = useState<KeyValue[]>();

    useAsyncEffect(useCallback(async () => {
        if (stateId) {
            const { current: hasCountyOptions } = hasCountyOptionsRef;

            if (hasCountyOptions) {
                // Only clear the county value after the initial county options have been fetched to avoid clearing
                // the existing value when editing
                setValue('request.property.county', '');
            }

            try {
                const newCountyOptions = await api.ppm.lookup.getCounties(stateId);
                newCountyOptions.sort((a, b) => a.key.localeCompare(b.key));

                setCountyOptions(newCountyOptions);
                hasCountyOptionsRef.current = true;
            } catch (error) {
                pageMessage.handleApiError('An error occurred while getting the county lookup options', error);
            }
        }
    }, [
        stateId, setValue, pageMessage
    ]));

    // Using the dirtyFields keys length because the isDirty boolean doesn't work properly
    const isDirty = !!Object.keys(formMethods.formState.dirtyFields).length;
    useEffect(() => {
        setIsFormDirty(isDirty);

        return () => setIsFormDirty(false);
    }, [ isDirty, setIsFormDirty ]);

    const loanPurpose = watch('request.loanInformation.loanPurpose');
    const isSecondMortgage = watch('request.loanInformation.lienPosition') === SECOND_MORTAGE_LEIN_POSITION;

    return (
        <div className={styles.root}>
            <FilledSection
                variant="light"
                className={clsx(styles.section, styles.formSection)}
                scrollable
            >
                <Form
                    id={ScenarioForm.formID}
                    onSubmit={saveScenario}
                    formMethods={formMethods}
                    className={styles.form}
                >
                    <TextField<ScenarioFormValues>
                        name="name"
                        label="Scenario name"
                        autoFocus
                        required
                    />

                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                        className={styles.header}
                    >
                        Product selection
                    </Typography>

                    <TextField<ScenarioFormValues>
                        name="request.productType"
                        label="Loan terms"
                        select
                        required
                        SelectProps={{
                            multiple: true
                        }}
                    >
                        {renderKeyValueOptions(lookups?.loanTerms)}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.standardProducts"
                        label="Standard products"
                        select
                        required
                        SelectProps={{
                            multiple: true
                        }}
                    >
                        {renderKeyValueOptions(lookups?.standardProducts)}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.productOptions"
                        label="Product options"
                        select
                        SelectProps={{
                            multiple: true
                        }}
                    >
                        {renderKeyValueOptions(lookups?.productOptions)}
                    </TextField>

                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                        className={styles.header}
                    >
                        Target price
                    </Typography>

                    <TextField<ScenarioFormValues>
                        name="targetPrice.minParRate"
                        label="Min par rate"
                        required
                        type="number"
                    />

                    <MaxParRateField />

                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                        className={styles.header}
                    >
                        Loan information
                    </Typography>

                    <TextField<ScenarioFormValues>
                        name="request.deliveryType"
                        label="Delivery type"
                        select
                        SelectProps={{
                            displayEmpty: true
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    >
                        <MenuItem value="">
                            None
                        </MenuItem>

                        {renderKeyValueOptions(lookups?.deliveryTypes)}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.lockDays"
                        label="Lock days"
                        select
                        required
                    >
                        {renderKeyValueOptions(lookups?.lockDays.map(({ lockDays }) => ({
                            key: `${lockDays} days`,
                            value: lockDays
                        })))}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.loanInformation.lienPosition"
                        label="Lien position"
                        select
                        defaultValue={lookups?.lienPositions[0]?.value}
                    >
                        {renderKeyValueOptions(lookups?.lienPositions)}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.loanInformation.loanPurpose"
                        label="Loan purpose"
                        select
                        defaultValue={lookups?.loanUsages[0]?.value}
                    >
                        {renderKeyValueOptions(lookups?.loanUsages)}
                    </TextField>

                    <CurrencyField<ScenarioFormValues>
                        name="request.loanInformation.firstMortgageAmount"
                        label={isSecondMortgage ? '1st Loan amount' : 'Loan amount'}
                        required
                    />

                    {isSecondMortgage && (
                        <>
                            <CurrencyField<ScenarioFormValues>
                                name="request.loanInformation.otherPayment"
                                label="1st Loan monthly payment"
                                required
                            />

                            <CurrencyField<ScenarioFormValues>
                                name="request.loanInformation.secondMortgageAmount"
                                label="2nd Loan amount"
                                required
                            />
                        </>
                    )}

                    <CurrencyField<ScenarioFormValues>
                        name="request.loanInformation.totalMortgageAmount"
                        label="Total loan amount"
                        required
                    />

                    {loanPurpose === CASH_OUT_LOAN_PURPOSE_VALUE && (
                        <CurrencyField<ScenarioFormValues>
                            name="request.loanInformation.cashOut"
                            label="Cash out amount"
                            required
                        />
                    )}

                    <TextField<ScenarioFormValues>
                        name="request.compensation.model"
                        label="Compensation"
                        select
                        defaultValue={2}
                    >
                        <MenuItem value={1}>Borrower</MenuItem>

                        <MenuItem value={2}>Creditor</MenuItem>
                    </TextField>

                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                        className={styles.header}
                    >
                        Borrower finances
                    </Typography>

                    <TextField<ScenarioFormValues>
                        name="request.borrowers.0.borrowerFinanancial.creditScore"
                        label="Credit score"
                        required
                    />

                    <TextField<ScenarioFormValues>
                        name="request.documentationLevel"
                        label="Documentation level"
                        select
                        required
                    >
                        {renderKeyValueOptions(lookups?.documentationTypes.map(({ documentationId }) => ({
                            value: documentationId,
                            key: documentationLevels[documentationId as keyof typeof documentationLevels]
                        })))}
                    </TextField>

                    <CurrencyField<ScenarioFormValues>
                        name="request.borrowers.0.borrowerFinanancial.liquidAsset"
                        label="Liquid assets"
                        required
                    />

                    <CurrencyField<ScenarioFormValues>
                        name="request.borrowers.0.borrowerFinanancial.income"
                        label="Annual income"
                        required
                    />

                    <Switch<ScenarioFormValues>
                        name="request.borrowerFinancialHistory.demonstrateHousingPaymentHistory"
                        label="Can demonstrate a 12-month mortgage/rental history"
                        className={styles.paymentHistory}
                    />

                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                        className={styles.header}
                    >
                        Property
                    </Typography>

                    <CurrencyField<ScenarioFormValues>
                        name="request.property.value"
                        label="Property value"
                        required
                    />

                    <TextField<ScenarioFormValues>
                        name="request.property.city"
                        label="City"
                        required
                    />

                    <TextField<ScenarioFormValues>
                        name="request.property.state"
                        label="State"
                        required
                        select
                    >
                        {lookups?.states.map(({ stateId, state, abbreviation }) => (
                            <MenuItem
                                value={abbreviation}
                                key={stateId}
                            >
                                <Tooltip title={state}>
                                    <span>{abbreviation}</span>
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.property.zip"
                        label="Zip"
                        required
                    />

                    <Tooltip
                        enterDelay={0}
                        title={stateId ? '' : 'Select a state to choose a county'}
                    >
                        <div>
                            <TextField<ScenarioFormValues>
                                name="request.property.county"
                                label="County"
                                required
                                select
                                disabled={!stateId}
                                fullWidth
                            >
                                {countyOptions?.map(({ key: countyName }) => (
                                    <MenuItem
                                        value={countyName}
                                        key={countyName}
                                    >
                                        {countyName}
                                    </MenuItem>
                                )) || (
                                    <MenuItem value={county} />
                                )}
                            </TextField>
                        </div>
                    </Tooltip>

                    <TextField<ScenarioFormValues>
                        name="request.property.type"
                        label="Property type"
                        select
                        defaultValue={lookups?.propertyTypes[0]?.value}
                    >
                        {renderKeyValueOptions(lookups?.propertyTypes)}
                    </TextField>

                    <TextField<ScenarioFormValues>
                        name="request.property.use"
                        label="Property use"
                        select
                        required
                    >
                        {renderKeyValueOptions(lookups?.propertyUses)}
                    </TextField>

                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                        className={styles.header}
                    >
                        Other
                    </Typography>

                    <TextField<ScenarioFormValues>
                        name="request.eppsUserName"
                        label="EPPS username"
                    />
                </Form>
            </FilledSection>

            <ScenarioPreviewSection createFetchResults={createFetchResults} />
        </div>
    );
}

ScenarioForm.formID = 'scenario-form';

function MaxParRateField() { // This is in its own component to isolate re-renders when changing the min
    const min = useWatch<ScenarioFormValues, 'targetPrice.minParRate'>({
        name: 'targetPrice.minParRate'
    });

    return (
        <TextField<ScenarioFormValues>
            name="targetPrice.maxParRate"
            label="Max par rate"
            type="number"
            rules={{
                min: {
                    value: min + .0000001,
                    message: 'Max par rate must be greater than the min'
                }
            }}
        />
    );
}

function renderKeyValueOptions(lookups: KeyValue[] | undefined) {
    return lookups?.map(({ key: label, value }) => (
        <MenuItem
            value={value || 0}
            key={value}
        >
            {label}
        </MenuItem>
    ));
}

function convertFormValuesToScenario(formValues: ScenarioFormValues): Scenario {
    const {
        targetPrice,
        request: {
            loanInformation, deliveryType, lockDays, property, borrowerFinancialHistory
        }
    } = formValues;

    formValues.request.borrowers[0].state = property.state;
    formValues.request.borrowers[0].email = 'a@b.com';
    formValues.request.borrowerFinancialHistory = borrowerFinancialHistory || {};

    const isSecondMortgage = loanInformation.lienPosition === SECOND_MORTAGE_LEIN_POSITION;

    return {
        ...formValues,
        targetPrice: {
            ...targetPrice,
            maxParRate: targetPrice.maxParRate || undefined
        },
        request: {
            ...formValues.request,
            deliveryType: deliveryType || undefined,
            lockDays: [ lockDays ],
            loanInformation: {
                ...loanInformation,
                cashOut: loanInformation.loanPurpose === CASH_OUT_LOAN_PURPOSE_VALUE
                    ? loanInformation.cashOut
                    : null,
                otherPayment: isSecondMortgage
                    ? loanInformation.otherPayment
                    : null,
                secondMortgageAmount: isSecondMortgage
                    ? loanInformation.secondMortgageAmount
                    : null
            }
        }
    };
}

function convertScenarioToFormValues(scenario: Scenario | undefined): DeepPartial<ScenarioFormValues> {
    const { lockDays, ...request } = scenario?.request || {};

    return !scenario ? getDefaultInitialValues() : {
        ...scenario,
        targetPrice: {
            ...scenario.targetPrice,
            maxParRate: scenario.targetPrice.maxParRate || undefined
        },
        request: {
            ...request,
            productOptions: scenario.request.productOptions || [],
            deliveryType: scenario.request.deliveryType || undefined, // Scrub for null
            lockDays: lockDays?.[0] || 0
        }
    };
}

function getDefaultInitialValues(): DeepPartial<ScenarioFormValues> {
    return {
        request: {
            productOptions: [],
            loanInformation: {
                target: 100
            }
        }
    };
}

interface EllieError {
    code: string;
    details: string;
    errors: EllieError[] | null;
}

function handlePossibleEllieError(message: string, error: any, pageMessage: ReturnType<typeof usePageMessage>) {
    if (isEllieError(error)) {
        if (error.errors === null) {
            pageMessage.error(message, [ error.details ]);
        } else {
            pageMessage.error(message, error.errors.map((error) => error.details));
        }
    } else if (isApiError(error)) {
        pageMessage.handleApiError(message, error);
    }
}

function isEllieError(error: any): error is EllieError {
    return error.hasOwnProperty('code')
        && error.hasOwnProperty('details')
        && error.hasOwnProperty('errors');
}
